import styled from 'styled-components';

export const ProposalAddFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ProposalAddFormButton = styled.div`
  margin-top: 20px;
`;

export const ProposalAddDeleteFileButton = styled.div`
  margin-top: 20px;
`;
