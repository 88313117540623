import {
  ButtonIconLinkContainer,
  ButtonIconSubmitContainer,
  ButtonLinkContainer,
  ButtonSubmitContainer,
  ButtonIconDeleteContainer,
} from './Button.elements';
import Loader from 'react-loader-spinner';

export const ButtonLink = ({
  children,
  to,
  height,
  width,
  fullwidth,
  btnType,
}) => {
  return (
    <ButtonLinkContainer
      to={to}
      height={height}
      width={width}
      fullwidth={fullwidth}
      type_button={btnType}
    >
      {children}
    </ButtonLinkContainer>
  );
};

export const ButtonIconDelete = ({
  type,
  id,
  name,
  children,
  onClicked,
  height,
  width,
  fullwidth,
}) => {
  return (
    <ButtonIconDeleteContainer
      type={type}
      id={id}
      name={name}
      onClick={onClicked}
      height={height}
      width={width}
      fullwidth={fullwidth}
    >
      {children}
    </ButtonIconDeleteContainer>
  );
};

export const ButtonSubmit = ({
  color,
  children,
  type,
  onClicked,
  height,
  fullwidth,
  width,
  btnType,
  loading,
  disabled,
}) => {
  return (
    <ButtonSubmitContainer
      color={color}
      type={type}
      onClick={onClicked}
      height={height}
      fullwidth={fullwidth}
      width={width}
      type_button={btnType}
      disabled={disabled}
    >
      {loading ? (
        <Loader type="TailSpin" color="#FFFFFF" height={30} width={30} />
      ) : (
        children
      )}
    </ButtonSubmitContainer>
  );
};

export const ButtonIconLink = ({ children, to, color }) => {
  return (
    <ButtonIconLinkContainer to={to} color={color}>
      {children}
    </ButtonIconLinkContainer>
  );
};

export const ButtonIconSubmit = ({ children, onClicked, color }) => {
  return (
    <ButtonIconSubmitContainer onClick={onClicked} color={color}>
      {children}
    </ButtonIconSubmitContainer>
  );
};
