import { useState } from 'react';
import { ButtonSubmit } from '../../../components/button/Button';
import Card, { CardHeader } from '../../../components/card/Card';
import PageLayout, {
  PageHeader,
} from '../../../components/page_layout/PageLayout';
import {
  InputField,
  inputFileClear,
} from '../../../components/text_field/TextField';
import {
  ProposalAddFormButton,
  ProposalAddFormContainer,
} from './ProposalAdd.elements';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

const ProposalAdd = () => {
  const history = useHistory();
  const [store, setStore] = useState(false);
  // const [study, setStudy] = useState([])
  const [form, setForm] = useState({
    type_of_proposal: 'Penelitian Kerjasama Industri',
    phone_number: '',
    admin_name: '',
    position: '',
    application_file: '',
    gpu: '',
    ram: '',
    storage: '',
    leader_name: '',
    pic: '',
    institution: '',
    duration: '',
    data_description: '',
    shared_data: '',
    activity_plan: '',
    collaboration_plan: '',
    research_fee: '',
    docker_image: '',
    collaboration_file: '',
    adhoc_file: '',
    institution_file: '',
    proposal_file: '',
    anggaran_file: '',
    term_1: '',
    term_2: '',
    term_3: '',
    error_list: [],
  });

  const type_of_gpu = [
    { label: '20GB', value: '20' },
    { label: '40GB', value: '40' },
  ];

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  const radioOption = [
    { value: 'yes', label: 'Iya' },
    { value: 'no', label: 'Tidak' },
  ];

  const inputChange = (name, value) => {
    setForm({ ...form, [name]: value });
  };

  const deleteFile = (e) => {
    const nameDeleteFile = '-delete-file';
    if (e.target.id === 'application_file' + nameDeleteFile) {
      inputFileClear('application_file');
      setForm({ ...form, application_file: '' });
    } else if (e.target.id === 'docker_image' + nameDeleteFile) {
      inputFileClear('docker_image');
      setForm({ ...form, docker_image: '' });
    } else if (e.target.id === 'anggaran_file' + nameDeleteFile) {
      inputFileClear('anggaran_file');
      setForm({ ...form, anggaran_file: '' });
    } else if (e.target.id === 'proposal_file' + nameDeleteFile) {
      inputFileClear('proposal_file');
      setForm({ ...form, proposal_file: '' });
    } else if (e.target.id === 'collaboration_file' + nameDeleteFile) {
      inputFileClear('collaboration_file');
      setForm({ ...form, collaboration_file: '' });
    } else if (e.target.id === 'adhoc_file' + nameDeleteFile) {
      inputFileClear('adhoc_file');
      setForm({ ...form, adhoc_file: '' });
    } else if (e.target.id === 'institution_file' + nameDeleteFile) {
      inputFileClear('institution_file');
      setForm({ ...form, institution_file: '' });
    }
  };

  const formSubmit = async () => {
    var term_and_condition = '';

    if (form.term_1 === '' || form.term_2 === '' || form.term_3 === '') {
      Swal.fire({
        icon: 'warning',
        title: 'Peringatan!',
        text: 'Harap lengkapi form terlebih dahulu.',
      });

      return false;
    } else {
      term_and_condition = 'agree';
    }

    setStore(true);

    const data = new FormData();

    data.append('type_of_proposal', 'Penelitian Kerjasama Industri');
    data.append('phone_number', form.phone_number);
    data.append('admin_name', form.admin_name);
    data.append('position', form.position);
    data.append('application_file', form.application_file);
    data.append('gpu', form.gpu);
    data.append('ram', form.ram);
    data.append('storage', form.storage);
    data.append('leader_name', form.leader_name);
    data.append('pic', form.pic);
    data.append('institution', form.institution);
    data.append('duration', form.duration);
    data.append('data_description', form.data_description);
    data.append('shared_data', form.shared_data);
    data.append('activity_plan', form.activity_plan);
    data.append('collaboration_plan', form.collaboration_plan);
    data.append('research_fee', form.research_fee);
    data.append('docker_image', form.docker_image);
    data.append('collaboration_file', form.collaboration_file);
    data.append('adhoc_file', form.adhoc_file);
    data.append('institution_file', form.institution_file);
    data.append('proposal_file', form.proposal_file);
    data.append('anggaran_file', form.anggaran_file);
    data.append('term_and_condition', term_and_condition);

    var url = '';
    if (localStorage.getItem('role') === 'Internal') {
      url = 'user-internal';
    } else if (localStorage.getItem('role') === 'External') {
      url = 'user-external';
    }

    await axios
      .post('/api/' + url + '/proposal-industri-submission/store', data)
      .then((res) => {
        if (res.data.meta.code === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Sukses!',
            text: 'Usulan berhasil diajukan.',
          });
          history.push('/user/dasbor');
        } else {
          setForm({ ...form, error_list: res.data.data.validation_errors });
        }
        setStore(false);
      })
      .catch((error) => {
        // console.error(error.response.data.data.validation_errors);
        if (error.response.data.meta.code === 422) {
          setForm({
            ...form,
            error_list: error.response.data.data.validation_errors,
          });
          setStore(false);
        }
      });
  };

  return (
    <>
      <PageLayout>
        <PageHeader title="Pengajuan Usulan Kerjasama Instansi" />

        <Card>
          <CardHeader title="Formulir Pengajuan Usulan Instansi" />
          <ProposalAddFormContainer>
            <InputField
              label="Jenis Penelitian"
              id="type_of_proposal"
              name="type_of_proposal"
              value="Penelitian Kerjasama Instansi"
              onChanged={inputChange}
              type="text"
              placeholder="Penelitian Kerjasama Instansi"
              error={form.error_list.type_of_proposal}
              disabled
            />
            <InputField
              label="Nomor Handphone"
              id="phone_number"
              name="phone_number"
              value={form.phone_number}
              onChanged={inputChange}
              type="number"
              placeholder="Masukkan Nomor Handphone (+62)"
              error={form.error_list.phone_number}
            />
            <InputField
              label="Nama Admin Tim AdHoc Internal UG"
              id="admin_name"
              name="admin_name"
              value={form.admin_name}
              onChanged={inputChange}
              type="text"
              placeholder="Masukkan Nama Admin Tim AdHoc Internal UG"
              error={form.error_list.admin_name}
            />
            <InputField
              label="Posisi dalam Tim AdHoc Internal"
              id="position"
              name="position"
              value={form.position}
              onChanged={inputChange}
              type="text"
              placeholder="Masukkan Posisi dalam Tim AdHoc Internal"
              error={form.error_list.position}
            />
            <InputField
              label="Unggah Surat Pengajuan Penggunaan DGX (Pdf)"
              id="application_file"
              name="application_file"
              onChanged={inputChange}
              type="file"
              error={form.error_list.application_file}
              onDeleteFile={deleteFile}
            />
            <InputField
              label="Jumlah GPU / (GB)"
              id="gpu"
              name="gpu"
              value={form.gpu}
              onChanged={inputChange}
              type="select"
              option={type_of_gpu.sort(dynamicSort('label'))}
              placeholder="Masukkan Jumlah GPU"
              error={form.error_list.gpu}
            />
            <InputField
              label="Jumlah RAM / (GB)"
              id="ram"
              name="ram"
              value={form.ram}
              onChanged={inputChange}
              type="number"
              placeholder="Masukkan Jumlah RAM"
              error={form.error_list.ram}
            />
            <InputField
              label="Jumlah Storage / (GB)"
              id="storage"
              name="storage"
              value={form.storage}
              onChanged={inputChange}
              type="number"
              placeholder="Masukkan Jumlah Storage"
              error={form.error_list.storage}
            />
            <InputField
              label="Nama Ketua Tim AdHoc"
              id="leader_name"
              name="leader_name"
              value={form.leader_name}
              onChanged={inputChange}
              type="text"
              placeholder="Masukkan Nama Ketua Tim AdHoc"
              error={form.error_list.leader_name}
            />
            <InputField
              label="Nama Penanggung Jawab External"
              id="pic"
              name="pic"
              value={form.pic}
              onChanged={inputChange}
              type="text"
              placeholder="Masukkan Nama Penanggung Jawab External"
              error={form.error_list.pic}
            />
            <InputField
              label="Nama Institusi Eksternal"
              id="institution"
              name="institution"
              value={form.institution}
              onChanged={inputChange}
              type="text"
              placeholder="Masukkan Nama Institusi Eksternal"
              error={form.error_list.institution}
            />
            <InputField
              label="Durasi / (Hari)"
              id="duration"
              name="duration"
              value={form.duration}
              onChanged={inputChange}
              type="number"
              placeholder="Masukkan Durasi"
              error={form.error_list.duration}
            />
            <InputField
              label="Deskripsi Data"
              id="data_description"
              name="data_description"
              value={form.data_description}
              onChanged={inputChange}
              type="textarea"
              placeholder="Masukkan Deskripsi Data"
              error={form.error_list.data_description}
            />
            <InputField
              label="Menggunakan Data Bersama"
              id="shared_data"
              name="shared_data"
              value={form.shared_data}
              onChanged={inputChange}
              type="radio"
              option={radioOption}
              error={form.error_list.shared_data}
            />
            <InputField
              label="Bentuk Kegiatan Kerjasama"
              id="collaboration_plan"
              name="collaboration_plan"
              value={form.collaboration_plan}
              onChanged={inputChange}
              type="text"
              placeholder="Masukkan Bentuk Kegiatan Kerjasama"
              error={form.error_list.collaboration_plan}
            />
            <InputField
              label="Rencana Kegiatan"
              id="activity_plan"
              name="activity_plan"
              value={form.activity_plan}
              onChanged={inputChange}
              type="textarea"
              placeholder="Masukkan Rencana Kegiatan"
              error={form.error_list.activity_plan}
            />
            <InputField
              label="Biaya Penelitian"
              id="research_fee"
              name="research_fee"
              value={form.research_fee}
              onChanged={inputChange}
              type="currency"
              placeholder="Masukkan Biaya Penelitian"
              error={form.error_list.research_fee}
            />
            <InputField
              label="Unggah Docker Image (Zip)"
              id="docker_image"
              name="docker_image"
              onChanged={inputChange}
              type="file"
              error={form.error_list.docker_image}
              onDeleteFile={deleteFile}
            />
            <InputField
              label="Unggah Scan Dokumen Kerjasama (Pdf)"
              id="collaboration_file"
              name="collaboration_file"
              onChanged={inputChange}
              type="file"
              error={form.error_list.collaboration_file}
              onDeleteFile={deleteFile}
            />
            <InputField
              label="Unggah Scan Dokumen Tim AdHoc UG (Pdf)"
              id="adhoc_file"
              name="adhoc_file"
              onChanged={inputChange}
              type="file"
              error={form.error_list.adhoc_file}
              onDeleteFile={deleteFile}
            />
            <InputField
              label="Unggah Profil Institusi External (Pdf)"
              id="institution_file"
              name="institution_file"
              onChanged={inputChange}
              type="file"
              error={form.error_list.institution_file}
              onDeleteFile={deleteFile}
            />
            <InputField
              label="Unggah Proposal Kegiatan (Pdf)"
              id="proposal_file"
              name="proposal_file"
              onChanged={inputChange}
              type="file"
              error={form.error_list.proposal_file}
              onDeleteFile={deleteFile}
            />
            <InputField
              label="Unggah Rencana Anggaran (Pdf)"
              id="anggaran_file"
              name="anggaran_file"
              onChanged={inputChange}
              type="file"
              error={form.error_list.anggaran_file}
              onDeleteFile={deleteFile}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                marginTop: '20px',
                paddingLeft: '20px',
                paddingRight: '20px',
              }}
            >
              <InputField
                label="Saya bersedia mematuhi tata cara penggunaan DGX yang telah ditetapkan, dan tidak akan memindahkan account login ke orang lain"
                id="term_1"
                name="term_1"
                value={1}
                onChanged={inputChange}
                type="checkbox"
                error={form.error_list.term_1}
              />
              <InputField
                label="Saya akan mengumpulkan laporan akhir pada masa berakhirnya penggunaan DGX pada penelitian"
                id="term_2"
                name="term_2"
                value={1}
                onChanged={inputChange}
                type="checkbox"
                error={form.error_list.term_2}
              />
              <InputField
                label="Saya telah mencoba membuat docker sesuai kebutuhan penggunaan di DGX dan akan mengirimkan kepada Tim Pengembangan DGX"
                id="term_3"
                name="term_3"
                value={1}
                onChanged={inputChange}
                type="checkbox"
                error={form.error_list.term_3}
              />
            </div>
            <ProposalAddFormButton>
              <ButtonSubmit
                color="primary"
                fullwidth
                height={50}
                type="submit"
                loading={store}
                onClicked={formSubmit}
                disabled={store}
              >
                Kirim
              </ButtonSubmit>
            </ProposalAddFormButton>
          </ProposalAddFormContainer>
        </Card>
      </PageLayout>
    </>
  );
};

export default ProposalAdd;
