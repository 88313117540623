import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  FaBook,
  FaCheck,
  FaCheckDouble,
  FaEye,
  FaFileInvoice,
  FaTimes,
  FaUsers,
  FaHistory,
} from 'react-icons/fa';
import Swal from 'sweetalert2';
import {
  ButtonIconLink,
  ButtonIconSubmit,
  ButtonSubmit,
} from '../../../components/button/Button';
import Card, { CardHeader, CardInfo } from '../../../components/card/Card';
import { LoadingElement } from '../../../components/loading/Loading';
import PageLayout, {
  PageHeader,
} from '../../../components/page_layout/PageLayout';
import TableAdmin, {
  TableAction,
  TableStatus,
} from '../../../components/table_admin/TableAdmin';
import { DashboardInfoContainer } from './Dashboard.elements';
import dateFormat from 'dateformat';
import Popup from '../../../components/popup/Popup';
import {
  InputField,
  TextEditor,
  inputFileClear,
} from '../../../components/text_field/TextField';
import { useHistory } from 'react-router-dom';

const Content = () => {
  const [get, setGet] = useState(true);
  const [content, setContent] = useState([]);

  useEffect(() => {
    const GetData = () => {
      var url = '';
      if (localStorage.getItem('role') === 'Content') {
        url = 'admin-content';
      } else if (localStorage.getItem('role') === 'Super') {
        url = 'admin-super';
      }

      axios.get(`/api/${url}/post/show-all`).then((res) => {
        if (res.data.meta.code === 200) {
          // console.log(res.data);
          // setContent(res.data.data.post)
          setContent(res.data);
          setGet(false);
        }
      });
    };

    GetData();
  }, []);

  // console.log(content);

  const post = [
    {
      field: 'title',
      headerName: 'Judul',
      width: 200,
      renderCell: (params) => {
        return (
          <>{params.row.title === null ? '(Tidak ada)' : params.row.title}</>
        );
      },
    },
    {
      field: 'slug',
      headerName: 'Slug',
      width: 200,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => {
        let newStatus = '';
        if (params.row.status === 'Post') {
          newStatus = 'Post';
        } else if (params.row.status === 'Draft') {
          newStatus = 'Draft';
        }

        return (
          <>
            <TableStatus status={params.row.status}>{newStatus}</TableStatus>
          </>
        );
      },
    },
    {
      field: 'created_at',
      headerName: 'Tanggal Pengajuan',
      width: 150,
      valueGetter: (params) => {
        const created_at = dateFormat(params.row.created_at, 'dd mmmm yyyy');
        return created_at;
      },
      renderCell: (params) => {
        const created_at = dateFormat(params.row.created_at, 'dd mmmm yyyy');
        return <>{created_at}</>;
      },
    },
  ];

  return (
    <PageLayout>
      <PageHeader title="Dasbor" />

      <Card>
        <CardHeader title="Daftar Konten" />
        <TableAdmin
          tableColumns={post}
          tableLoading={get}
          tableRows={content}
        />
      </Card>
    </PageLayout>
  );
};

const Proposal = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [days, setDays] = useState([]);
  const [machine, setMachine] = useState([]);
  const [loadingApproved, setLoadingApproved] = useState(false);
  const [loadingRejected, setLoadingRejected] = useState(false);
  const [popupApproved, setPopupApproved] = useState(false);
  const [popupRejected, setPopupRejected] = useState(false);
  const [formApproved, setFormApproved] = useState({
    id_proposal: '',
    docker_image: '',
    type_of_proposal: '',
    username: '',
    id_hari: '',
    durasi: '',
    id_mesin: '',
    appr_description: '',
    error_list: '',
  });
  const [formRejected, setFormRejected] = useState({
    id_proposal: '',
    userID: '',
    rev_description: '',
    error_list: [],
  });
  const [proposal, setProposal] = useState([]);
  const [user, setUser] = useState([]);

  const inputApprovedChange = (name, value) => {
    setFormApproved({ ...formApproved, [name]: value });
  };

  const inputRejectedChange = (name, value) => {
    setFormRejected({ ...formRejected, [name]: value });
  };

  const GetDetail = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
    };

    fetch(`${process.env.REACT_APP_API_URL_2}/hari`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        result.data.map((v) => {
          const data = {
            label: v.nama.toLowerCase().replace(/\b[a-z]/g, function (letter) {
              return letter.toUpperCase();
            }),
            value: v.id,
          };

          return setDays((days) => [...days, data]);
        });
      })
      .catch((error) => console.error('error', error));

    fetch(`${process.env.REACT_APP_API_URL_2}/mesin`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        result.data.map((v) => {
          const data = {
            label: v.nama_mesin,
            value: v.id_mesin,
          };

          return setMachine((machine) => [...machine, data]);
        });
      })
      .catch((error) => console.error('error', error));

    var url = '';
    if (localStorage.getItem('role') === 'Proposal') {
      url = 'admin-proposal';
    } else if (localStorage.getItem('role') === 'Super') {
      url = 'admin-super';
    }

    axios
      .get(`/api/${url}/proposal-submission/show-all-submission`)
      .then((res) => {
        if (res.data.meta.code === 200) {
          setProposal([
            ...res.data.data.submission,
            ...res.data.data.non_ta_submission,
            ...res.data.data.industri_submission,
          ]);
        }
      });

    axios.get(`/api/${url}/user/show-all-user`).then((res) => {
      if (res.data.meta.code === 200) {
        setUser(res.data.data.user);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    GetDetail();
  }, []);

  const handleApproved = (id) => {
    setFormApproved({
      ...formApproved,
      id_proposal: id,
      username: proposal.filter((v) => v.id === id)[0].user.email,
      docker_image: proposal.filter((v) => v.id === id)[0].docker_image,
      type_of_proposal: proposal.filter((v) => v.id === id)[0].type_of_proposal,
    });
    setPopupApproved(!popupApproved);
  };

  const handleRevision = (id, userID) => {
    setFormRejected({
      ...formRejected,
      userID: userID,
      id_proposal: id,
    });
    setPopupRejected(!popupRejected);
  };

  function countStatus(type) {
    const countTypes = proposal.filter((search) => search.status === type);
    return countTypes.length;
  }

  if (loading) {
    return <LoadingElement />;
  }

  const approvedSubmit = (id) => {
    setLoadingApproved(false);

    var url = '';
    if (localStorage.getItem('role') === 'Proposal') {
      url = 'admin-proposal';
    } else if (localStorage.getItem('role') === 'Super') {
      url = 'admin-super';
    }

    Swal.fire({
      icon: 'question',
      title: 'Yakin ingin menyetujui?',
      text: 'Harap periksa data baik-baik sebelum menyetujui.',
      showCancelButton: true,
      confirmButtonColor: '#5B3A89',
      cancelButtonColor: '#F34636',
      cancelButtonText: 'Batal',
      confirmButtonText: 'Setuju',
    }).then((result) => {
      if (result.isConfirmed) {
        var formdata = new FormData();

        formdata.append('DockerImages', formApproved.docker_image);
        formdata.append('username', formApproved.username);
        formdata.append('id_hari', formApproved.id_hari);
        formdata.append('durasi', formApproved.durasi);
        formdata.append('id_mesin', formApproved.id_mesin);
        formdata.append('appr_description', formApproved.appr_description);

        // var requestOptions = {
        //   method: 'POST',
        //   body: formdata,
        //   redirect: 'follow',
        // };
        let type = '';
        if (formApproved.type_of_proposal === 'Penelitian TA') {
          type = 'submission';
        } else if (formApproved.type_of_proposal === 'Penelitian Non TA') {
          type = 'non-ta-submission';
        } else if (
          formApproved.type_of_proposal === 'Penelitian Kerjasama Industri'
        ) {
          type = 'industri-submission';
        }

        if (formApproved.appr_description === null) {
          Swal.fire({
            icon: 'warning',
            text: 'harap lengkapi form.',
          });

          return false;
        }

        axios
          .post(`/api/${url}/proposal-${type}/approved/${id}`, formdata)
          .then((res) => {
            if (res.data.meta.code === 200) {
              Swal.fire({
                icon: 'success',
                title: 'Sukses!',
                text: 'Proposal berhasil disetujui.',
              });
              GetDetail();
              inputFileClear();
              setPopupRejected(!popupRejected);
              history.push('/admin/dasbor');
            } else {
              Swal.fire({
                icon: 'danger',
                title: 'Gagal!',
                text: 'Proposal gagal disetujui.',
              });
              setFormApproved({
                ...formApproved,
                error_list: res.data.data.validation_errors,
              });
            }
          });

        // fetch(`${process.env.REACT_APP_API_URL_2}/approval`, requestOptions)
        //   .then((response) => {
        //     if (!response.ok) {
        //       throw response;
        //     }
        //     return response.json();
        //   })
        //   .then((result) => {
        //     if (result.error === true) {
        //       Swal.fire({
        //         icon: 'warning',
        //         title: result.message,
        //       });
        //     } else {
        //       var formdata = new FormData();

        //       formdata.append(
        //         'appr_description',
        //         formApproved.appr_description
        //       );

        //       axios
        //         .post(
        //           '/api/' + url + '/proposal-submission/approved/' + id,
        //           formdata
        //         )
        //         .then((res) => {
        //           if (res.data.meta.code === 200) {
        //             Swal.fire({
        //               icon: 'success',
        //               title: 'Sukses!',
        //               text: 'Proposal berhasil disetujui.',
        //             });
        //             GetDetail();
        //             inputFileClear();
        //             setPopupRejected(!popupRejected);
        //             history.push('/admin/dasbor');
        //           } else {
        //             Swal.fire({
        //               icon: 'danger',
        //               title: 'Gagal!',
        //               text: 'Proposal gagal disetujui.',
        //             });
        //             setFormApproved({
        //               ...formApproved,
        //               error_list: res.data.data.validation_errors,
        //             });
        //           }
        //         });
        //     }
        //   })
        //   .catch((error) => {
        //     if (error.status === 422) {
        //       Swal.fire({
        //         icon: 'warning',
        //         title: error.statusText,
        //         text: 'harap lengkapi form.',
        //       });
        //     }
        //     return false;
        //   });
      }
    });
  };

  const revisionSubmit = (id, userID) => {
    setLoadingRejected(true);

    var url = '';
    if (localStorage.getItem('role') === 'Proposal') {
      url = 'admin-proposal';
    } else if (localStorage.getItem('role') === 'Super') {
      url = 'admin-super';
    }

    Swal.fire({
      icon: 'question',
      title: 'Yakin ingin merevisi?',
      text: 'Harap periksa data baik-baik sebelum revisi.',
      showCancelButton: true,
      confirmButtonColor: '#5B3A89',
      cancelButtonColor: '#F34636',
      cancelButtonText: 'Batal',
      confirmButtonText: 'Setuju',
    })
      .then((result) => {
        if (result.isConfirmed) {
          var formdata = new FormData();

          formdata.append('proposal_id', id);
          formdata.append('catatan', formRejected.rev_description);
          formdata.append('user_id', userID);

          axios.post('/api/' + url + '/revisistore', formdata).then((res) => {
            if (res.data.meta.code === 200) {
              Swal.fire({
                icon: 'success',
                title: 'Sukses!',
                text: 'Proposal berhasil Revisi.',
              });
              GetDetail();
              inputFileClear();
              setPopupRejected(!popupRejected);
              history.push('/admin/dasbor');
            } else {
              setFormRejected({
                ...formRejected,
                error_list: res.data.data.validation_errors,
              });
            }
            setLoadingRejected(false);
          });
        } else {
          setLoadingRejected(false);
        }
      })
      .catch((err) => console.error(err));
  };

  const rejectedSubmit = (id, type_of_proposal) => {
    var url = '';
    if (localStorage.getItem('role') === 'Proposal') {
      url = 'admin-proposal';
    } else if (localStorage.getItem('role') === 'Super') {
      url = 'admin-super';
    }

    Swal.fire({
      icon: 'question',
      title: 'Yakin ingin menolak?',
      text: 'Harap periksa data baik-baik sebelum menolak.',
      showCancelButton: true,
      confirmButtonColor: '#5B3A89',
      cancelButtonColor: '#F34636',
      cancelButtonText: 'Batal',
      confirmButtonText: 'Setuju',
    }).then((result) => {
      if (result.isConfirmed) {
        let type = '';
        if (type_of_proposal === 'Penelitian TA') {
          type = 'submission';
        } else if (type_of_proposal === 'Penelitian Non TA') {
          type = 'non-ta-submission';
        } else if (type_of_proposal === 'Penelitian Kerjasama Industri') {
          type = 'industri-submission';
        }
        axios
          .post(`/api/${url}/proposal-${type}/rejected/${id}`)
          .then((res) => {
            if (res.data.meta.code === 200) {
              Swal.fire({
                icon: 'success',
                title: 'Sukses!',
                text: 'Proposal berhasil ditolak.',
              });
              GetDetail();
              history.push('/admin/dasbor');
            }
          });
      }
    });
  };

  const finishedSubmit = (id) => {
    var url = '';
    if (localStorage.getItem('role') === 'Proposal') {
      url = 'admin-proposal';
    } else if (localStorage.getItem('role') === 'Super') {
      url = 'admin-super';
    }

    Swal.fire({
      icon: 'question',
      title: 'Yakin ingin menyelesaikan?',
      text: 'Harap periksa data baik-baik sebelum menyetujui.',
      showCancelButton: true,
      confirmButtonColor: '#5B3A89',
      cancelButtonColor: '#F34636',
      cancelButtonText: 'Batal',
      confirmButtonText: 'Setuju',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post('/api/' + url + '/proposal-submission/finished/' + id)
          .then((res) => {
            if (res.data.meta.code === 200) {
              Swal.fire({
                icon: 'success',
                title: 'Sukses!',
                text: 'Proposal berhasil Revisi.',
              });
              GetDetail();
              history.push('/admin/dasbor');
            } else {
              Swal.fire({
                icon: 'danger',
                title: 'Gagal!',
                text: 'Proposal gagal Revisi.',
              });
            }
          });
      }
    });
  };

  const columnsProposal = [
    {
      field: 'username',
      headerName: 'Username',
      width: 200,
      valueGetter: (params) => {
        return params.row.user.email;
      },
      renderCell: (params) => {
        return params.row.user.email;
      },
    },
    {
      field: 'type_of_proposal',
      headerName: 'Jenis Penelitian',
      width: 200,
    },
    {
      field: 'research_field',
      headerName: 'Bidang Penelitian',
      width: 200,
    },
    {
      field: 'short_description',
      headerName: 'Deskripsi Singkat Penelitian',
      width: 350,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      valueGetter: (params) => {
        let newStatus = '';
        if (params.row.status === 'approved') {
          newStatus = 'Disetujui';
        } else if (params.row.status === 'rejected') {
          newStatus = 'ditolak';
        } else if (params.row.status === 'pending') {
          newStatus = 'Belum Disetujui';
        } else if (params.row.status === 'finished') {
          newStatus = 'Selesai';
        } else if (params.row.status === 'revision') {
          newStatus = 'Revisi';
        }

        return newStatus;
      },
      renderCell: (params) => {
        let newStatus = '';
        if (params.row.status === 'Approved') {
          newStatus = 'Disetujui';
        } else if (params.row.status === 'Rejected') {
          newStatus = 'Ditolak';
        } else if (params.row.status === 'Pending') {
          newStatus = 'Belum Disetujui';
        } else if (params.row.status === 'Finished') {
          newStatus = 'Selesai';
        } else if (params.row.status === 'Revision') {
          newStatus = 'Revisi';
        }

        return (
          <>
            <TableStatus status={params.row.status}>{newStatus}</TableStatus>
          </>
        );
      },
    },
    {
      field: 'created_at',
      headerName: 'Tanggal Pengajuan',
      width: 150,
      valueGetter: (params) => {
        const created_at = dateFormat(params.row.created_at, 'dd mmmm yyyy');
        return created_at;
      },
      renderCell: (params) => {
        const created_at = dateFormat(params.row.created_at, 'dd mmmm yyyy');
        return <>{created_at}</>;
      },
    },
    {
      field: 'action',
      headerName: 'Aksi',
      width: 150,
      disableExport: true,
      filterable: false,
      renderCell: (params) => {
        let element = '';

        if (params.row.status === 'Pending') {
          element = (
            <>
              <ButtonIconSubmit
                onClicked={() => handleApproved(params.row.id)}
                color="success"
              >
                <FaCheck />
              </ButtonIconSubmit>
              <Popup
                trigger={popupApproved}
                setTrigger={setPopupApproved}
                title="Approval"
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                  }}
                >
                  <InputField
                    label="Docker Image"
                    id="docker_image"
                    name="docker_image"
                    value={formApproved.docker_image}
                    onChanged={inputApprovedChange}
                    type="text"
                    readOnly
                  />
                  <InputField
                    label="Username"
                    id="username"
                    name="username"
                    value={formApproved.username}
                    onChanged={inputApprovedChange}
                    type="text"
                    readOnly
                  />
                  <InputField
                    label="Hari"
                    id="id_hari"
                    name="id_hari"
                    value={formApproved.id_hari}
                    onChanged={inputApprovedChange}
                    type="select"
                    option={days}
                    placeholder={'Pilih Hari'}
                    isLoading={loading}
                  />
                  <InputField
                    label="Durasi (Hari)"
                    id="durasi"
                    name="durasi"
                    value={formApproved.durasi}
                    onChanged={inputApprovedChange}
                    type="number"
                  />
                  <InputField
                    label="Mesin"
                    id="id_mesin"
                    name="id_mesin"
                    value={formApproved.id_mesin}
                    onChanged={inputApprovedChange}
                    type="select"
                    option={machine}
                    placeholder={'Pilih Mesin'}
                    isLoading={loading}
                  />
                  <TextEditor
                    label="Detail Setujui"
                    name="appr_description"
                    onChanged={inputApprovedChange}
                    error={formApproved.error_list.appr_description}
                  />
                  <div
                    style={{
                      marginTop: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <ButtonSubmit
                      color="primary"
                      loading={loadingApproved}
                      onClicked={() => approvedSubmit(formApproved.id_proposal)}
                    >
                      Tambah
                    </ButtonSubmit>
                  </div>
                </div>
              </Popup>
              <ButtonIconSubmit
                onClicked={() =>
                  handleRevision(params.row.id, params.row.user_id)
                }
                color="warning"
              >
                <FaBook />
              </ButtonIconSubmit>
              <ButtonIconSubmit
                onClicked={() =>
                  rejectedSubmit(params.row.id, params.row.type_of_proposal)
                }
                color="danger"
              >
                <FaTimes />
              </ButtonIconSubmit>
              <Popup
                trigger={popupRejected}
                setTrigger={setPopupRejected}
                title="Detail Revisi"
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                  }}
                >
                  <TextEditor
                    name="rev_description"
                    value={formRejected.rev_description}
                    onChanged={() => inputRejectedChange}
                    error={formRejected.error_list.rev_description}
                  />
                  <div
                    style={{
                      marginTop: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <ButtonSubmit
                      color="primary"
                      loading={loadingRejected}
                      onClicked={() =>
                        revisionSubmit(
                          formRejected.id_proposal,
                          formRejected.userID
                        )
                      }
                    >
                      Revisi
                    </ButtonSubmit>
                  </div>
                </div>
              </Popup>
            </>
          );
        } else if (params.row.status === 'Approved') {
          element = (
            <>
              <ButtonIconSubmit
                onClicked={() => finishedSubmit(params.row.id)}
                color="primary"
              >
                <FaCheckDouble />
              </ButtonIconSubmit>
              <ButtonIconLink
                to={'/admin/revision-history/' + params.row.id}
                // onClicked={() => rejectedSubmit(params.row.id)}
                color="secondary"
              >
                <FaHistory />
              </ButtonIconLink>
            </>
          );
        } else if (params.row.status === 'Revision') {
          element = (
            <>
              <ButtonIconLink
                to={'/admin/revision-history/' + params.row.id}
                color="secondary"
              >
                <FaHistory />
              </ButtonIconLink>
            </>
          );
        }
        return (
          <TableAction>
            <ButtonIconLink
              to={'/admin/usulan/pratinjau-na/' + params.row.id}
              color="info"
            >
              <FaEye />
            </ButtonIconLink>
            {element}
          </TableAction>
        );
      },
    },
  ];

  return (
    <PageLayout>
      <PageHeader title="Dasbor" />

      <DashboardInfoContainer>
        <CardInfo
          icon={<FaFileInvoice />}
          title="Menunggu"
          count={countStatus('Pending')}
          type="warning"
        />
        <CardInfo
          icon={<FaFileInvoice />}
          title="Disetujui"
          count={countStatus('Approved')}
          type="success"
        />
        <CardInfo
          icon={<FaFileInvoice />}
          title="Ditolak"
          count={countStatus('Rejected')}
          type="danger"
        />
        <CardInfo
          icon={<FaUsers />}
          title="User"
          count={user.length}
          type="default"
        />
      </DashboardInfoContainer>

      <Card>
        <TableAdmin
          tableColumns={columnsProposal}
          tableLoading={loading}
          tableRows={proposal}
        />
      </Card>
    </PageLayout>
  );
};

const Super = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [days, setDays] = useState([]);
  const [machine, setMachine] = useState([]);
  const [loadingApproved, setLoadingApproved] = useState(false);
  const [loadingRejected, setLoadingRejected] = useState(false);
  const [popupApproved, setPopupApproved] = useState(false);
  const [popupRejected, setPopupRejected] = useState(false);
  const [formApproved, setFormApproved] = useState({
    id_proposal: '',
    docker_image: '',
    type_of_proposal: '',
    username: '',
    id_hari: '',
    durasi: '',
    id_mesin: '',
    appr_description: '',
    error_list: '',
  });
  const [formRejected, setFormRejected] = useState({
    id_proposal: '',
    userID: '',
    rev_description: '',
    error_list: [],
  });
  const [proposal, setProposal] = useState([]);
  const [user, setUser] = useState([]);

  const inputApprovedChange = (name, value) => {
    setFormApproved({ ...formApproved, [name]: value });
  };

  const inputRejectedChange = (name, value) => {
    setFormRejected({ ...formRejected, [name]: value });
  };

  const GetDetail = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
    };

    fetch(`${process.env.REACT_APP_API_URL_2}/hari`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        result.data.map((v) => {
          const data = {
            label: v.nama.toLowerCase().replace(/\b[a-z]/g, function (letter) {
              return letter.toUpperCase();
            }),
            value: v.id,
          };

          return setDays((days) => [...days, data]);
        });
      })
      .catch((error) => console.error('error', error));

    fetch(`${process.env.REACT_APP_API_URL_2}/mesin`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        result.data.map((v) => {
          const data = {
            label: v.nama_mesin,
            value: v.id_mesin,
          };

          return setMachine((machine) => [...machine, data]);
        });
      })
      .catch((error) => console.error('error', error));

    var url = '';
    if (localStorage.getItem('role') === 'Proposal') {
      url = 'admin-proposal';
    } else if (localStorage.getItem('role') === 'Super') {
      url = 'admin-super';
    }

    axios
      .get(`/api/${url}/proposal-submission/show-all-submission`)
      .then((res) => {
        if (res.data.meta.code === 200) {
          setProposal([
            ...res.data.data.submission,
            ...res.data.data.non_ta_submission,
            ...res.data.data.industri_submission,
          ]);
        }
      });

    axios.get(`/api/${url}/user/show-all-user`).then((res) => {
      if (res.data.meta.code === 200) {
        setUser(res.data.data.user);
        setLoading(false);
      }
    });
  };

  // console.log('user state: ', user);

  useEffect(() => {
    GetDetail();
  }, []);

  const handleApproved = (id) => {
    setFormApproved({
      ...formApproved,
      id_proposal: id,
      username: proposal.filter((v) => v.id === id)[0].user.email,
      docker_image: proposal.filter((v) => v.id === id)[0].docker_image,
      type_of_proposal: proposal.filter((v) => v.id === id)[0].type_of_proposal,
    });
    setPopupApproved(!popupApproved);
  };

  const handleRevision = (id, userID) => {
    setFormRejected({
      ...formRejected,
      userID: userID,
      id_proposal: id,
    });
    setPopupRejected(!popupRejected);
  };

  function countStatus(type) {
    const countTypes = proposal.filter((search) => search.status === type);
    return countTypes.length;
  }

  if (loading) {
    return <LoadingElement />;
  }

  const approvedSubmit = (id) => {
    setLoadingApproved(false);

    var url = '';
    if (localStorage.getItem('role') === 'Proposal') {
      url = 'admin-proposal';
    } else if (localStorage.getItem('role') === 'Super') {
      url = 'admin-super';
    }

    Swal.fire({
      icon: 'question',
      title: 'Yakin ingin menyetujui?',
      text: 'Harap periksa data baik-baik sebelum menyetujui.',
      showCancelButton: true,
      confirmButtonColor: '#5B3A89',
      cancelButtonColor: '#F34636',
      cancelButtonText: 'Batal',
      confirmButtonText: 'Setuju',
    }).then((result) => {
      if (result.isConfirmed) {
        var formdata = new FormData();

        formdata.append('DockerImages', formApproved.docker_image);
        formdata.append('username', formApproved.username);
        formdata.append('id_hari', formApproved.id_hari);
        formdata.append('durasi', formApproved.durasi);
        formdata.append('id_mesin', formApproved.id_mesin);
        formdata.append('appr_description', formApproved.appr_description);

        // var requestOptions = {
        //   method: 'POST',
        //   body: formdata,
        //   redirect: 'follow',
        // };

        if (formApproved.appr_description === null) {
          Swal.fire({
            icon: 'warning',
            text: 'harap lengkapi form.',
          });

          return false;
        }

        let type = '';
        if (formApproved.type_of_proposal === 'Penelitian TA') {
          type = 'submission';
        } else if (formApproved.type_of_proposal === 'Penelitian Non TA') {
          type = 'non-ta-submission';
        } else if (
          formApproved.type_of_proposal === 'Penelitian Kerjasama Industri'
        ) {
          type = 'industri-submission';
        }

        axios
          .post(`/api/${url}/proposal-${type}/approved/${id}`, formdata)
          .then((res) => {
            if (res.data.meta.code === 200) {
              Swal.fire({
                icon: 'success',
                title: 'Sukses!',
                text: 'Proposal berhasil disetujui.',
              });
              GetDetail();
              inputFileClear();
              setPopupApproved(!popupApproved);
              history.push('/admin/dasbor');
            } else {
              Swal.fire({
                icon: 'danger',
                title: 'Gagal!',
                text: 'Proposal gagal disetujui.',
              });
              setFormApproved({
                ...formApproved,
                error_list: res.data.data.validation_errors,
              });
            }
          });

        // fetch(`${process.env.REACT_APP_API_URL_2}/approval`, requestOptions)
        //   .then((response) => {
        //     if (!response.ok) {
        //       throw response;
        //     }
        //     return response.json();
        //   })
        //   .then((result) => {
        //     if (result.error === true) {
        //       Swal.fire({
        //         icon: 'warning',
        //         title: result.message,
        //       });
        //     } else {
        //       var formdata = new FormData();

        //       formdata.append(
        //         'appr_description',
        //         formApproved.appr_description
        //       );

        //       axios
        //         .post(
        //           '/api/' + url + '/proposal-submission/approved/' + id,
        //           formdata
        //         )
        //         .then((res) => {
        //           if (res.data.meta.code === 200) {
        //             Swal.fire({
        //               icon: 'success',
        //               title: 'Sukses!',
        //               text: 'Proposal berhasil disetujui.',
        //             });
        //             GetDetail();
        //             inputFileClear();
        //             setPopupApproved(!popupApproved);
        //             history.push('/admin/dasbor');
        //           } else {
        //             Swal.fire({
        //               icon: 'danger',
        //               title: 'Gagal!',
        //               text: 'Proposal gagal disetujui.',
        //             });
        //             setFormApproved({
        //               ...formApproved,
        //               error_list: res.data.data.validation_errors,
        //             });
        //           }
        //         });
        //     }
        //   })
        //   .catch((error) => {
        //     if (error.status === 422) {
        //       Swal.fire({
        //         icon: 'warning',
        //         title: error.statusText,
        //         text: 'harap lengkapi form.',
        //       });
        //     }
        //     return false;
        //   });
      }
    });
  };

  const revisionSubmit = () => {
    setLoadingRejected(true);

    var url = '';
    if (localStorage.getItem('role') === 'Proposal') {
      url = 'admin-proposal';
    } else if (localStorage.getItem('role') === 'Super') {
      url = 'admin-super';
    }

    Swal.fire({
      icon: 'question',
      title: 'Yakin ingin merevisi?',
      text: 'Harap periksa data baik-baik sebelum revisi.',
      showCancelButton: true,
      confirmButtonColor: '#5B3A89',
      cancelButtonColor: '#F34636',
      cancelButtonText: 'Batal',
      confirmButtonText: 'Setuju',
    })
      .then((result) => {
        if (result.isConfirmed) {
          var formdata = new FormData();

          formdata.append('proposal_id', formRejected.id_proposal);
          formdata.append('catatan', formRejected.rev_description);
          formdata.append('user_id', formRejected.userID);

          axios.post('/api/' + url + '/revisistore', formdata).then((res) => {
            if (res.data.meta.code === 200) {
              Swal.fire({
                icon: 'success',
                title: 'Sukses!',
                text: 'Proposal berhasil Revisi.',
              });
              GetDetail();
              inputFileClear();
              setPopupRejected(!popupRejected);
              history.push('/admin/dasbor');
            } else {
              setFormRejected({
                ...formRejected,
                error_list: res.data.data.validation_errors,
              });
            }
            setLoadingRejected(false);
          });
        } else {
          setLoadingRejected(false);
        }
      })
      .catch((err) => console.error(err));
  };

  const rejectedSubmit = (id, type_of_proposal) => {
    var url = '';
    if (localStorage.getItem('role') === 'Proposal') {
      url = 'admin-proposal';
    } else if (localStorage.getItem('role') === 'Super') {
      url = 'admin-super';
    }

    Swal.fire({
      icon: 'question',
      title: 'Yakin ingin menolak?',
      text: 'Harap periksa data baik-baik sebelum menolak.',
      showCancelButton: true,
      confirmButtonColor: '#5B3A89',
      cancelButtonColor: '#F34636',
      cancelButtonText: 'Batal',
      confirmButtonText: 'Setuju',
    }).then((result) => {
      if (result.isConfirmed) {
        let type = '';
        if (type_of_proposal === 'Penelitian TA') {
          type = 'submission';
        } else if (type_of_proposal === 'Penelitian Non TA') {
          type = 'non-ta-submission';
        } else if (type_of_proposal === 'Penelitian Kerjasama Industri') {
          type = 'industri-submission';
        }

        axios
          .post(`/api/${url}/proposal-${type}/rejected/${id}`)
          .then((res) => {
            if (res.data.meta.code === 200) {
              Swal.fire({
                icon: 'success',
                title: 'Sukses!',
                text: 'Proposal berhasil ditolak.',
              });
              GetDetail();
            }
          });
      }
    });
  };

  const finishedSubmit = (id) => {
    var url = '';
    if (localStorage.getItem('role') === 'Proposal') {
      url = 'admin-proposal';
    } else if (localStorage.getItem('role') === 'Super') {
      url = 'admin-super';
    }

    Swal.fire({
      icon: 'question',
      title: 'Yakin ingin menyelesaikan?',
      text: 'Harap periksa data baik-baik sebelum menyetujui.',
      showCancelButton: true,
      confirmButtonColor: '#5B3A89',
      cancelButtonColor: '#F34636',
      cancelButtonText: 'Batal',
      confirmButtonText: 'Setuju',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post('/api/' + url + '/proposal-submission/finished/' + id)
          .then((res) => {
            if (res.data.meta.code === 200) {
              Swal.fire({
                icon: 'success',
                title: 'Sukses!',
                text: 'Proposal berhasil Revisi.',
              });
              GetDetail();
            } else {
              Swal.fire({
                icon: 'danger',
                title: 'Gagal!',
                text: 'Proposal gagal Revisi.',
              });
            }
          });
      }
    });
  };

  const columnsProposal = [
    {
      field: 'username',
      headerName: 'Username',
      width: 270,
      valueGetter: (params) => {
        return params.row.user.email;
      },
      renderCell: (params) => {
        return params.row.user.email;
      },
    },
    {
      field: 'type_of_proposal',
      headerName: 'Jenis Penelitian',
      width: 200,
    },
    {
      field: 'research_field',
      headerName: 'Bidang Penelitian',
      width: 200,
    },
    {
      field: 'short_description',
      headerName: 'Deskripsi Singkat Penelitian',
      width: 350,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      valueGetter: (params) => {
        let newStatus = '';
        if (params.row.status === 'approved') {
          newStatus = 'Disetujui';
        } else if (params.row.status === 'rejected') {
          newStatus = 'ditolak';
        } else if (params.row.status === 'pending') {
          newStatus = 'Belum Disetujui';
        } else if (params.row.status === 'finished') {
          newStatus = 'Selesai';
        } else if (params.row.status === 'revision') {
          newStatus = 'Revisi';
        }

        return newStatus;
      },
      renderCell: (params) => {
        let newStatus = '';
        if (params.row.status === 'Approved') {
          newStatus = 'Disetujui';
        } else if (params.row.status === 'Rejected') {
          newStatus = 'Ditolak';
        } else if (params.row.status === 'Pending') {
          newStatus = 'Belum Disetujui';
        } else if (params.row.status === 'Finished') {
          newStatus = 'Selesai';
        } else if (params.row.status === 'Revision') {
          newStatus = 'Revisi';
        }

        return (
          <>
            <TableStatus status={params.row.status}>{newStatus}</TableStatus>
          </>
        );
      },
    },
    {
      field: 'created_at',
      headerName: 'Tanggal Pengajuan',
      width: 150,
      valueGetter: (params) => {
        const created_at = dateFormat(params.row.created_at, 'dd mmmm yyyy');
        return created_at;
      },
      renderCell: (params) => {
        const created_at = dateFormat(params.row.created_at, 'dd mmmm yyyy');
        return <>{created_at}</>;
      },
    },
    {
      field: 'action',
      headerName: 'Aksi',
      width: 200,
      disableExport: true,
      filterable: false,
      renderCell: (params) => {
        let element = '';

        if (params.row.status === 'Pending') {
          element = (
            <>
              <ButtonIconSubmit
                onClicked={() => handleApproved(params.row.id)}
                color="success"
              >
                <FaCheck />
              </ButtonIconSubmit>
              <Popup
                trigger={popupApproved}
                setTrigger={setPopupApproved}
                title="Approval"
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                  }}
                >
                  <InputField
                    label="Docker Image"
                    id="docker_image"
                    name="docker_image"
                    value={formApproved.docker_image}
                    onChanged={inputApprovedChange}
                    type="text"
                    readOnly
                  />
                  <InputField
                    label="Username"
                    id="username"
                    name="username"
                    value={formApproved.username}
                    onChanged={inputApprovedChange}
                    type="text"
                    readOnly
                  />
                  <InputField
                    label="Hari"
                    id="id_hari"
                    name="id_hari"
                    value={formApproved.id_hari}
                    onChanged={inputApprovedChange}
                    type="select"
                    option={days}
                    placeholder={'Pilih Hari'}
                    isLoading={loading}
                  />
                  <InputField
                    label="Durasi (Hari)"
                    id="durasi"
                    name="durasi"
                    value={formApproved.durasi}
                    onChanged={inputApprovedChange}
                    type="number"
                  />
                  <InputField
                    label="Mesin"
                    id="id_mesin"
                    name="id_mesin"
                    value={formApproved.id_mesin}
                    onChanged={inputApprovedChange}
                    type="select"
                    option={machine}
                    placeholder={'Pilih Mesin'}
                    isLoading={loading}
                  />
                  <TextEditor
                    label="Detail Setujui"
                    name="appr_description"
                    onChanged={inputApprovedChange}
                    error={formApproved.error_list.appr_description}
                  />
                  <div
                    style={{
                      marginTop: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <ButtonSubmit
                      color="primary"
                      loading={loadingApproved}
                      onClicked={() => approvedSubmit(formApproved.id_proposal)}
                    >
                      Tambah
                    </ButtonSubmit>
                  </div>
                </div>
              </Popup>
              <ButtonIconSubmit
                onClicked={() =>
                  handleRevision(params.row.id, params.row.user_id)
                }
                color="warning"
              >
                <FaBook />
              </ButtonIconSubmit>
              <ButtonIconSubmit
                onClicked={() =>
                  rejectedSubmit(params.row.id, params.row.type_of_proposal)
                }
                color="danger"
              >
                <FaTimes />
              </ButtonIconSubmit>
              <Popup
                trigger={popupRejected}
                setTrigger={setPopupRejected}
                title="Detail Revisi"
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                  }}
                >
                  <TextEditor
                    name="rev_description"
                    onChanged={inputRejectedChange}
                    error={formRejected.error_list.appr_description}
                  />
                  <div
                    style={{
                      marginTop: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <ButtonSubmit
                      color="primary"
                      loading={loadingRejected}
                      onClicked={revisionSubmit}
                    >
                      Revisi
                    </ButtonSubmit>
                  </div>
                </div>
              </Popup>
            </>
          );
        } else if (params.row.status === 'Approved') {
          element = (
            <>
              <ButtonIconSubmit
                onClicked={() => finishedSubmit(params.row.id)}
                color="primary"
              >
                <FaCheckDouble />
              </ButtonIconSubmit>
              <ButtonIconLink
                to={'/admin/revision-history/' + params.row.id}
                // onClicked={() => rejectedSubmit(params.row.id)}
                color="secondary"
              >
                <FaHistory />
              </ButtonIconLink>
            </>
          );
        } else if (params.row.status === 'Revision') {
          element = (
            <>
              <ButtonIconLink
                to={'/admin/revision-history/' + params.row.id}
                // onClicked={() => rejectedSubmit(params.row.id)}
                color="secondary"
              >
                <FaHistory />
              </ButtonIconLink>
            </>
          );
        }
        if (
          params.row.status === 'Pending' ||
          params.row.status === 'Revision'
        ) {
          if (params.row.type_of_proposal === 'Penelitian Non TA') {
            return (
              <TableAction>
                <ButtonIconLink
                  to={'/admin/usulan-non-ta/pratinjau/' + params.row.id}
                  color="info"
                >
                  <FaEye />
                </ButtonIconLink>
                {element}
              </TableAction>
            );
          } else if (params.row.type_of_proposal === 'Penelitian TA') {
            return (
              <TableAction>
                <ButtonIconLink
                  to={'/admin/usulan/pratinjau/' + params.row.id}
                  color="info"
                >
                  <FaEye />
                </ButtonIconLink>
                {element}
              </TableAction>
            );
          } else if (
            params.row.type_of_proposal === 'Penelitian Kerjasama Industri'
          ) {
            return (
              <TableAction>
                <ButtonIconLink
                  to={'/admin/usulan-industri/pratinjau/' + params.row.id}
                  color="info"
                >
                  <FaEye />
                </ButtonIconLink>
                {element}
              </TableAction>
            );
          }
        }
      },
    },
  ];

  return (
    <PageLayout>
      <PageHeader title="Dasbor" />

      <DashboardInfoContainer>
        <CardInfo
          icon={<FaFileInvoice />}
          title="Menunggu"
          count={countStatus('Pending')}
          type="warning"
        />
        <CardInfo
          icon={<FaFileInvoice />}
          title="Disetujui"
          count={countStatus('Approved')}
          type="success"
        />
        <CardInfo
          icon={<FaFileInvoice />}
          title="Ditolak"
          count={countStatus('Rejected')}
          type="danger"
        />
        <CardInfo
          icon={<FaUsers />}
          title="User"
          count={user.length}
          type="default"
        />
      </DashboardInfoContainer>

      <Card>
        <TableAdmin
          tableColumns={columnsProposal}
          tableLoading={loading}
          tableRows={proposal}
          dataCSV={proposal}
        />
      </Card>
    </PageLayout>
  );
};

const Dashboard = () => {
  if (localStorage.getItem('role') === 'Content') {
    return <Content />;
  } else if (localStorage.getItem('role') === 'Proposal') {
    return <Proposal />;
  } else if (localStorage.getItem('role') === 'Super') {
    return <Super />;
  }
};

export default Dashboard;
