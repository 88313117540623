import { Stack } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
  TableActionContainer,
  TableContainer,
  TableStatusContainer,
} from './TableAdmin.elements';
import { CSVLink } from 'react-csv';
import './style.css';

const TableAdmin = ({
  dataCSV,
  tableRows,
  tableLoading,
  tableColumns,
  pageSize,
  tableId,
  tableInfo,
}) => {
  const dateNow = () => {
    const date = new Date();
    const month = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    let tanggal = date.getDate();
    let bulan = month[date.getMonth()];
    let tahun = date.getFullYear();

    return `${tanggal}-${bulan}-${tahun}`;
  };

  // console.log(dateNow());
  return (
    <>
      <div className="container-custom">
        <CSVLink
          data={dataCSV}
          className="button-csv"
          target="_blank"
          filename={`all-proposal-${dateNow()}.csv`}
        >
          Download as CSV
        </CSVLink>
      </div>
      <TableContainer>
        <DataGrid
          getRowId={tableId && (() => tableId)}
          // getRowId={(row) => row.id}
          pageSize={pageSize ? pageSize : 100}
          loading={tableLoading}
          rows={tableRows}
          columns={tableColumns}
          localeText={{
            toolbarDensity: 'Size',
            toolbarDensityLabel: 'Size',
            toolbarDensityCompact: 'Small',
            toolbarDensityStandard: 'Medium',
            toolbarDensityComfortable: 'Large',
          }}
          components={{
            Toolbar: GridToolbar,
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                {tableInfo ? tableInfo : 'Tidak ada data'}
              </Stack>
            ),
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Data tidak ditemukan
              </Stack>
            ),
          }}
        />
      </TableContainer>
    </>
  );
};

export const TableAction = ({ children }) => {
  return <TableActionContainer>{children}</TableActionContainer>;
};

export const TableStatus = ({ status, children }) => {
  return (
    <TableStatusContainer status={status}>{children}</TableStatusContainer>
  );
};

export default TableAdmin;
