import Dashboard from "../../views/user/dashboard/Dashboard";
import Procedure from "../../views/user/procedure/Procedure";
import Profile from "../../views/user/profile/Profile";
import ProposalAdd from "../../views/user/proposal_add/ProposalAdd";
import ProposalEdit from "../../views/user/proposal_edit/ProposalEdit";
import ProposalPreview from "../../views/user/proposal_preview/ProposalPreview";
import ProposalAddNonTA from "../../views/user/proposal_non_add/ProposalAdd";
import ProposalEditNonTA from "../../views/user/proposal_non_edit/ProposalEdit";
import ProposalPreviewNonTA from "../../views/user/proposal_non_preview/ProposalPreview";
import ProposalAddIndustri from "../../views/user/proposal_industri_add/ProposalAdd";
import ProposalEditIndustri from "../../views/user/proposal_industri_edit/ProposalEdit";
import ProposalPreviewIndustri from "../../views/user/proposal_industri_preview/ProposalPreview";

export const UserRouter = [
    { path: "/user", exact: true, name: "User" },
    { path: "/user/dasbor", exact: true, name: "User Dashboard", component: Dashboard },
    { path: "/user/pengajuan-usulan", exact: true, name: "User Proposal Add", component: ProposalAdd },
    { path: "/user/usulan/ubah/:id", exact: true, name: "User Proposal Edit", component: ProposalEdit },
    { path: "/user/usulan/pratinjau/:id", exact: true, name: "User Proposal Preview", component: ProposalPreview },
    { path: "/user/pengajuan-usulan-non-ta", exact: true, name: "User Proposal Non TA Add", component: ProposalAddNonTA },
    { path: "/user/usulan-non-ta/ubah/:id", exact: true, name: "User Proposal Non TA Edit", component: ProposalEditNonTA },
    { path: "/user/usulan-non-ta/pratinjau/:id", exact: true, name: "User Proposal Non TA Preview", component: ProposalPreviewNonTA },
    { path: "/user/pengajuan-usulan-industri", exact: true, name: "User Proposal Industri Add", component: ProposalAddIndustri },
    { path: "/user/usulan-industri/ubah/:id", exact: true, name: "User Proposal Industri Edit", component: ProposalEditIndustri },
    { path: "/user/usulan-industri/pratinjau/:id", exact: true, name: "User Proposal Industri Preview", component: ProposalPreviewIndustri },
    { path: "/user/profil", exact: true, name: "User Profile", component: Profile },
    { path: "/user/prosedur-template", exact: true, name: "User Procedure", component: Procedure },
]