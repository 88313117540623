import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import Card from '../../../components/card/Card';
import { LoadingElement } from '../../../components/loading/Loading';
import PageLayout, {
  PageHeader,
} from '../../../components/page_layout/PageLayout';
import dateFormat from 'dateformat';
import { useParams } from 'react-router-dom';

const Proposal = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [proposal, setProposal] = useState([]);

  const GetDetail = () => {
    var url = '';
    if (localStorage.getItem('role') === 'Proposal') {
      url = 'admin-proposal';
    } else if (localStorage.getItem('role') === 'Super') {
      url = 'admin-super';
    }

    axios.get(`/api/${url}/revisishow/${id}`).then((res) => {
      if (res.data.meta.code === 200) {
        setProposal(res.data.data.proposal_revisi);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    GetDetail();
  });

  if (loading) {
    return <LoadingElement />;
  }

  return (
    <PageLayout>
      <PageHeader title="Riwayat Revisi" />
      <Card>
        <div
          style={{
            backgroundColor: '#5B3A89',
            padding: '1rem',
            borderRadius: '5px',
            color: '#ffffff',
          }}
        >
          <h3 style={{ color: '#ffffff' }}>Catatan Revisi yang Diberikan :</h3>
          <div style={{ padding: '0.5rem 0 0 2rem' }}>
            {proposal.map((res) => (
              <div dangerouslySetInnerHTML={{ __html: res.catatan }}></div>
            ))}
          </div>
          <h3 style={{ color: '#ffffff' }}>Tanggal Revisi yang Diberikan :</h3>
          <div style={{ padding: '0.5rem 0 0 2rem' }}>
            {proposal.map((res) => (
              <p
                dangerouslySetInnerHTML={{
                  __html: dateFormat(res.tanggal_revisi, 'dd mmmm yyyy'),
                }}
              ></p>
            ))}
          </div>
        </div>
      </Card>
    </PageLayout>
  );
};

const RevisionHistory = () => {
  return <Proposal />;
};

export default RevisionHistory;
