import { useEffect, useState } from 'react';
import { ButtonLink, ButtonSubmit } from '../../../components/button/Button';
import Card, { CardHeader } from '../../../components/card/Card';
import PageLayout, {
  PageHeader,
} from '../../../components/page_layout/PageLayout';
import {
  InputField,
  TextEditor,
} from '../../../components/text_field/TextField';
import {
  ProposalPreviewFormButton,
  ProposalPreviewFormContainer,
} from './ProposalPreview.elements';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useParams, useHistory } from 'react-router-dom';
import { LoadingElement } from '../../../components/loading/Loading';
import {
  FaBook,
  FaCheck,
  FaCheckDouble,
  FaTimes,
  FaHistory,
} from 'react-icons/fa';
import Popup from '../../../components/popup/Popup';

const ProposalPreview = () => {
  const history = useHistory();
  const { id } = useParams();
  const [get, setGet] = useState(true);
  const [days, setDays] = useState([]);
  const [machine, setMachine] = useState([]);
  const [loadingApproved, setLoadingApproved] = useState(false);
  const [loadingRejected, setLoadingRejected] = useState(false);
  const [popupApproved, setPopupApproved] = useState(false);
  const [popupRevision, setPopupRevision] = useState(false);
  const [dataProposal, setDataProposal] = useState([]);
  const [visible, setVisible] = useState(false);
  const [userID, setUserID] = useState();
  const [formRejected, setFormRejected] = useState({
    rev_description: '',
    error_list: [],
  });
  const [formApproved, setFormApproved] = useState({
    docker_image: '',
    username: '',
    id_hari: '',
    durasi: '',
    appr_description: '',
    id_mesin: '',
    error_list: '',
  });
  const [form, setForm] = useState({
    type_of_proposal: '',
    phone_number: '',
    educational_level: '',
    application_file: '',
    study_program: '',
    gpu: '',
    ram: '',
    storage: '',
    partner: '',
    duration: '',
    research_field: '',
    short_description: '',
    data_description: '',
    shared_data: '',
    activity_plan: '',
    output_plan: '',
    previous_experience: '',
    research_fee: '',
    docker_image: '',
    previous_proposal_file: '',
    proposal_file: '',
    anggaran_file: '',
    status: '',
    pembimbing1: '',
    pembimbing2: '',
    pembimbing3: '',
    error_list: [],
  });

  const inputApprovedChange = (name, value) => {
    setFormApproved({ ...formApproved, [name]: value });
  };

  const inputRejectedChange = (name, value) => {
    setFormRejected({ ...formRejected, [name]: value });
  };

  const GetUpdate = () => {
    var url = '';
    if (localStorage.getItem('role') === 'Proposal') {
      url = 'admin-proposal';
    } else if (localStorage.getItem('role') === 'Super') {
      url = 'admin-super';
    }

    axios.get('/api/' + url + '/proposal-submission/show/' + id).then((res) => {
      if (res.data.meta.code === 200) {
        setForm({
          type_of_proposal: res.data.data.submission.type_of_proposal,
          phone_number: res.data.data.submission.phone_number,
          educational_level: res.data.data.submission.educational_level,
          application_file: res.data.data.submission.application_file,
          study_program: res.data.data.submission.study_program,
          gpu: res.data.data.submission.gpu,
          ram: res.data.data.submission.ram,
          storage: res.data.data.submission.storage,
          partner: res.data.data.submission.partner,
          duration: res.data.data.submission.duration,
          research_field: res.data.data.submission.research_field,
          short_description: res.data.data.submission.short_description,
          data_description: res.data.data.submission.data_description,
          shared_data:
            res.data.data.submission.shared_data === 1 ? 'yes' : 'no',
          activity_plan: res.data.data.submission.activity_plan,
          output_plan: res.data.data.submission.output_plan,
          previous_experience: res.data.data.submission.previous_experience,
          research_fee: res.data.data.submission.research_fee,
          docker_image: res.data.data.submission.docker_image,
          previous_proposal_file: res.data.data.submission.proposal_file,
          proposal_file: '',
          anggaran_file: res.data.data.submission.anggaran_file,
          status: res.data.data.submission.status,
          pembimbing1: res.data.data.submission.pembimbing1,
          pembimbing2: res.data.data.submission.pembimbing2,
          pembimbing3: res.data.data.submission.pembimbing3,
          error_list: [],
        });
      }
      setGet(false);
    });
  };

  useEffect(() => {
    const GetDetail = () => {
      var url = '';
      if (localStorage.getItem('role') === 'Proposal') {
        url = 'admin-proposal';
      } else if (localStorage.getItem('role') === 'Super') {
        url = 'admin-super';
      }

      var requestOptions = {
        method: 'GET',
        redirect: 'follow',
      };

      fetch(`${process.env.REACT_APP_API_URL_2}/hari`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          result.data.map((v) => {
            const data = {
              label: v.nama
                .toLowerCase()
                .replace(/\b[a-z]/g, function (letter) {
                  return letter.toUpperCase();
                }),
              value: v.id,
            };

            return setDays((days) => [...days, data]);
          });
        })
        .catch((error) => console.log('error', error));

      fetch(`${process.env.REACT_APP_API_URL_2}/mesin`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          result.data.map((v) => {
            const data = {
              label: v.nama_mesin,
              value: v.id_mesin,
            };

            return setMachine((machine) => [...machine, data]);
          });
        })
        .catch((error) => console.log('error', error));

      axios.get(`/api/${url}/revisishow/${id}`).then((res) => {
        if (res.data.meta.code === 200) {
          if (res.data.data.proposal_revisi.length !== 0) {
            setDataProposal(res.data.data.proposal_revisi);
            setVisible(true);
          }
        }
      });

      axios
        .get('/api/' + url + '/proposal-submission/show/' + id)
        .then((res) => {
          if (res.data.meta.code === 200) {
            setFormApproved({
              id_proposal: id,
              docker_image: res.data.data.submission.docker_image,
              username: res.data.data.submission.user.email,
              id_hari: '',
              durasi: '',
              id_mesin: '',
              error_list: '',
            });
            setForm({
              type_of_proposal: res.data.data.submission.type_of_proposal,
              phone_number: res.data.data.submission.phone_number,
              educational_level: res.data.data.submission.educational_level,
              application_file: res.data.data.submission.application_file,
              study_program: res.data.data.submission.study_program,
              gpu: res.data.data.submission.gpu,
              ram: res.data.data.submission.ram,
              storage: res.data.data.submission.storage,
              partner: res.data.data.submission.partner,
              duration: res.data.data.submission.duration,
              research_field: res.data.data.submission.research_field,
              short_description: res.data.data.submission.short_description,
              data_description: res.data.data.submission.data_description,
              shared_data:
                res.data.data.submission.shared_data === 1 ? 'yes' : 'no',
              activity_plan: res.data.data.submission.activity_plan,
              output_plan: res.data.data.submission.output_plan,
              previous_experience: res.data.data.submission.previous_experience,
              research_fee: res.data.data.submission.research_fee,
              docker_image: res.data.data.submission.docker_image,
              previous_proposal_file: res.data.data.submission.proposal_file,
              proposal_file: '',
              anggaran_file: res.data.data.submission.anggaran_file,
              status: res.data.data.submission.status,
              pembimbing1: res.data.data.submission.pembimbing1,
              pembimbing2: res.data.data.submission.pembimbing2,
              pembimbing3: res.data.data.submission.pembimbing3,
              error_list: [],
            });
            setUserID(res.data.data.submission.user_id);
          }
          setGet(false);
        });
    };

    GetDetail();
  }, [id]);

  if (get) {
    return <LoadingElement />;
  }

  const approvedSubmit = () => {
    setLoadingApproved(false);

    var url = '';
    if (localStorage.getItem('role') === 'Proposal') {
      url = 'admin-proposal';
    } else if (localStorage.getItem('role') === 'Super') {
      url = 'admin-super';
    }

    Swal.fire({
      icon: 'question',
      title: 'Yakin ingin menyetujui?',
      text: 'Harap periksa data baik-baik sebelum menyetujui.',
      showCancelButton: true,
      confirmButtonColor: '#5B3A89',
      cancelButtonColor: '#F34636',
      cancelButtonText: 'Batal',
      confirmButtonText: 'Setuju',
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.isConfirmed) {
          var formdata = new FormData();

          formdata.append('DockerImages', formApproved.docker_image);
          formdata.append('username', formApproved.username);
          formdata.append('id_hari', formApproved.id_hari);
          formdata.append('durasi', formApproved.durasi);
          formdata.append('id_mesin', formApproved.id_mesin);
          formdata.append('appr_description', formApproved.appr_description);

          // var requestOptions = {
          //   method: 'POST',
          //   body: formdata,
          //   redirect: 'follow',
          // };

          if (formApproved.appr_description === null) {
            Swal.fire({
              icon: 'warning',
              text: 'harap lengkapi form.',
            });

            return false;
          }

          axios
            .post(
              '/api/' + url + '/proposal-submission/approved/' + id,
              formdata
            )
            .then((res) => {
              if (res.data.meta.code === 200) {
                Swal.fire({
                  icon: 'success',
                  title: 'Sukses!',
                  text: 'Proposal berhasil disetujui.',
                });
                GetUpdate();
              } else {
                Swal.fire({
                  icon: 'danger',
                  title: 'Gagal!',
                  text: 'Proposal gagal disetujui.',
                });
                setFormApproved({
                  ...formApproved,
                  error_list: res.data.data.validation_errors,
                });
              }
            });

          // fetch(`${process.env.REACT_APP_API_URL_2}/approval`, requestOptions)
          //   .then((response) => {
          //     if (!response.ok) {
          //       throw response;
          //     }
          //     return response.json();
          //   })
          //   .then((result) => {
          //     if (result.error === true) {
          //       Swal.fire({
          //         icon: 'warning',
          //         title: result.message,
          //       });
          //     } else {
          //       var formdata = new FormData();

          //       formdata.append(
          //         'appr_description',
          //         formApproved.appr_description
          //       );

          //       axios
          //         .post(
          //           '/api/' + url + '/proposal-submission/approved/' + id,
          //           formdata
          //         )
          //         .then((res) => {
          //           if (res.data.meta.code === 200) {
          //             Swal.fire({
          //               icon: 'success',
          //               title: 'Sukses!',
          //               text: 'Proposal berhasil disetujui.',
          //             });
          //             GetUpdate();
          //           } else {
          //             Swal.fire({
          //               icon: 'danger',
          //               title: 'Gagal!',
          //               text: 'Proposal gagal disetujui.',
          //             });
          //             setFormApproved({
          //               ...formApproved,
          //               error_list: res.data.data.validation_errors,
          //             });
          //           }
          //         });
          //     }
          //   })
          //   .catch((error) => {
          //     if (error.status === 422) {
          //       Swal.fire({
          //         icon: 'warning',
          //         title: error.statusText,
          //         text: 'harap lengkapi form.',
          //       });
          //     }
          //     return false;
          //   });
        }
      }
    });
  };

  const revisionSubmit = () => {
    setLoadingRejected(true);

    var url = '';
    if (localStorage.getItem('role') === 'Proposal') {
      url = 'admin-proposal';
    } else if (localStorage.getItem('role') === 'Super') {
      url = 'admin-super';
    }

    Swal.fire({
      icon: 'question',
      title: 'Yakin ingin merevisi?',
      text: 'Harap periksa data baik-baik sebelum revisi.',
      showCancelButton: true,
      confirmButtonColor: '#5B3A89',
      cancelButtonColor: '#F34636',
      cancelButtonText: 'Batal',
      confirmButtonText: 'Setuju',
    }).then((result) => {
      if (result.isConfirmed) {
        var formdata = new FormData();

        // formdata.append('rev_description', formRejected.rev_description);
        formdata.append('proposal_id', id);
        formdata.append('catatan', formRejected.rev_description);
        formdata.append('user_id', userID);

        // axios
        //   .post('/api/' + url + '/proposal-submission/revision/' + id, formdata)
        //   .then((res) => {
        //     if (res.data.meta.code === 200) {
        //       Swal.fire({
        //         icon: 'success',
        //         title: 'Sukses!',
        //         text: 'Proposal berhasil Revisi.',
        //       });
        //       GetUpdate();
        //     } else {
        //       setFormRejected({
        //         ...formRejected,
        //         error_list: res.data.data.validation_errors,
        //       });
        //     }
        //     setLoadingRejected(false);
        //   });
        axios.post('/api/' + url + '/revisistore', formdata).then((res) => {
          if (res.data.meta.code === 200) {
            Swal.fire({
              icon: 'success',
              title: 'Sukses!',
              text: 'Proposal berhasil Revisi.',
            });
            GetUpdate();
            history.push('/admin/dasbor');
          } else {
            setFormRejected({
              ...formRejected,
              error_list: res.data.data.validation_errors,
            });
          }
          setLoadingRejected(false);
        });
      } else {
        setLoadingRejected(false);
      }
    });
  };

  const finishedSubmit = () => {
    var url = '';
    if (localStorage.getItem('role') === 'Proposal') {
      url = 'admin-proposal';
    } else if (localStorage.getItem('role') === 'Super') {
      url = 'admin-super';
    }

    Swal.fire({
      icon: 'question',
      title: 'Yakin ingin merevisi?',
      text: 'Harap periksa data baik-baik sebelum menyetujui.',
      showCancelButton: true,
      confirmButtonColor: '#5B3A89',
      cancelButtonColor: '#F34636',
      cancelButtonText: 'Batal',
      confirmButtonText: 'Setuju',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post('/api/' + url + '/proposal-submission/finished/' + id)
          .then((res) => {
            if (res.data.meta.code === 200) {
              Swal.fire({
                icon: 'success',
                title: 'Sukses!',
                text: 'Proposal berhasil Revisi.',
              });
              GetUpdate();
            } else {
              Swal.fire({
                icon: 'danger',
                title: 'Gagal!',
                text: 'Proposal gagal Revisi.',
              });
            }
          });
      }
    });
  };

  const rejectedSubmit = () => {
    var url = '';
    if (localStorage.getItem('role') === 'Proposal') {
      url = 'admin-proposal';
    } else if (localStorage.getItem('role') === 'Super') {
      url = 'admin-super';
    }

    Swal.fire({
      icon: 'question',
      title: 'Yakin ingin menolak?',
      text: 'Harap periksa data baik-baik sebelum menolak.',
      showCancelButton: true,
      confirmButtonColor: '#5B3A89',
      cancelButtonColor: '#F34636',
      cancelButtonText: 'Batal',
      confirmButtonText: 'Setuju',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post('/api/' + url + '/proposal-submission/rejected/' + id)
          .then((res) => {
            if (res.data.meta.code === 200) {
              Swal.fire({
                icon: 'success',
                title: 'Sukses!',
                text: 'Proposal berhasil ditolak.',
              });
              GetUpdate();
            }
          });
      }
    });
  };

  var status = '';

  if (form.status === 'Pending') {
    status = 'Belum Disetujui';
  } else if (form.status === 'Approved') {
    status = 'Disetujui';
  } else if (form.status === 'Rejected') {
    status = 'Ditolak';
  } else if (form.status === 'Revision') {
    status = 'Revisi';
  } else if (form.status === 'Finished') {
    status = 'Selesai';
  }

  let element = '';

  if (form.status === 'Pending') {
    element = (
      <>
        <ButtonSubmit
          color="success"
          fullwidth
          height={50}
          type="submit"
          onClicked={() => setPopupApproved(!popupApproved)}
        >
          <FaCheck />
          Setujui
        </ButtonSubmit>
        <ButtonSubmit
          color="warning"
          fullwidth
          height={50}
          type="submit"
          onClicked={() => setPopupRevision(!popupRevision)}
        >
          <FaBook />
          Revisi
        </ButtonSubmit>
        <ButtonSubmit
          color="danger"
          fullwidth
          height={50}
          type="submit"
          onClicked={() => rejectedSubmit(id)}
        >
          <FaTimes />
          Tolak
        </ButtonSubmit>
        <ButtonLink
          color="secondary"
          fullwidth
          height={50}
          to={'/admin/revision-history/' + id}
        >
          <FaHistory />
          Riwayat
        </ButtonLink>
      </>
    );
  } else if (form.status === 'Approved') {
    element = (
      <ButtonSubmit
        color="primary"
        fullwidth
        height={50}
        type="submit"
        onClicked={finishedSubmit}
      >
        <FaCheckDouble />
        Selesai
      </ButtonSubmit>
    );
  } else if (form.status === 'Revision') {
    element = (
      <ButtonLink
        color="secondary"
        fullwidth
        height={50}
        to={'/admin/revision-history/' + id}
      >
        <FaHistory />
        Riwayat
      </ButtonLink>
    );
  }

  return (
    <>
      <PageLayout>
        <PageHeader title="Pratinjau Usulan TA" />
        {visible ? (
          <div
            style={{
              backgroundColor: '#5B3A89',
              padding: '1rem',
              marginBottom: '2rem',
              borderRadius: '5px',
              color: '#ffffff',
            }}
          >
            <h3 style={{ color: '#ffffff' }}>
              Catatan Revisi yang Diberikan :
            </h3>
            <div style={{ padding: '0.5rem 0 0 2rem' }}>
              {dataProposal.map((res) => (
                <div dangerouslySetInnerHTML={{ __html: res.catatan }}></div>
              ))}
            </div>
          </div>
        ) : null}
        <Card>
          <CardHeader title="Formulir Pengajuan Usulan TA" />
          <ProposalPreviewFormContainer>
            <InputField
              label="Jenis Penelitian"
              value={form.type_of_proposal}
              readOnly
            />
            <InputField
              label="Nomor Handphone"
              value={form.phone_number}
              readOnly
            />
            <InputField
              label="Jenjang Pendidikan"
              value={form.educational_level}
              readOnly
            />
            <InputField
              label="File Permohonan"
              type="see-file"
              onClicked={() =>
                window.open(
                  process.env.REACT_APP_API_URL +
                    '/application_dgx/file/' +
                    form.application_file,
                  '_blank'
                )
              }
            />
            <InputField
              label="Program Studi"
              value={form.study_program}
              readOnly
            />
            <InputField label="Jumlah GPU / (GB)" value={form.gpu} readOnly />
            <InputField label="Jumlah RAM / (GB)" value={form.ram} readOnly />
            <InputField
              label="Jumlah Storage / (GB)"
              value={form.storage}
              readOnly
            />
            <InputField
              label="Nama Partner / Mahasiswa"
              value={form.partner}
              readOnly
            />
            <InputField
              label="Nama Pembimbing 1"
              value={form.pembimbing1}
              readOnly
            />
            <InputField
              label="Nama Pembimbing 2"
              value={form.pembimbing2}
              readOnly
            />
            <InputField
              label="Nama Pembimbing 3"
              value={form.pembimbing3}
              readOnly
            />
            <InputField
              label="Durasi / (Hari)"
              value={form.duration}
              readOnly
            />
            <InputField
              label="Bidang Penelitian"
              value={form.research_field}
              readOnly
            />
            <InputField
              label="Deskripsi Singkat Penelitian"
              value={form.short_description}
              id="short_description"
              type="textarea"
              readOnly
            />
            <InputField
              label="Deskripsi Data"
              value={form.data_description}
              id="data_description"
              type="textarea"
              readOnly
            />
            <InputField
              label="Menggunakan Data Bersama"
              value={form.shared_data === 'yes' ? 'Iya' : 'Tidak'}
              readOnly
            />
            <InputField
              label="Rencana Kegiatan"
              value={form.activity_plan}
              id="activity_plan"
              type="textarea"
              readOnly
            />
            <InputField
              label="Rencana Output Penelitian"
              value={form.output_plan}
              id="output_plan"
              type="textarea"
              readOnly
            />
            <InputField
              label="Pengalaman Penelitian Sebelumnya"
              value={form.previous_experience}
              id="revious_experience"
              type="textarea"
              readOnly
            />
            <InputField
              label="Docker Image"
              type="see-file"
              onClicked={() =>
                window.open(
                  process.env.REACT_APP_API_URL +
                    '/docker/file/' +
                    form.docker_image,
                  '_blank'
                )
              }
            />
            {/* <InputField
              label="Docker Image"
              value={}
              readOnly
            /> */}
            <InputField
              label="Biaya Penelitian"
              value={form.research_fee}
              type="currency"
              readOnly
            />
            <InputField label="Status" value={status} readOnly />
            <InputField
              label="File Proposal"
              type="see-file"
              onClicked={() =>
                window.open(
                  process.env.REACT_APP_API_URL +
                    '/proposal/file/' +
                    form.previous_proposal_file,
                  '_blank'
                )
              }
            />
            <InputField
              label="File Rencana Anggaran"
              type="see-file"
              onClicked={() =>
                window.open(
                  process.env.REACT_APP_API_URL +
                    '/anggaran/file/' +
                    form.anggaran_file,
                  '_blank'
                )
              }
            />
            <InputField label="Status" value={status} readOnly />
          </ProposalPreviewFormContainer>
          <Popup
            trigger={popupApproved}
            setTrigger={setPopupApproved}
            title="Tambah Mesin"
          >
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
            >
              <InputField
                label="Docker Image"
                id="docker_image"
                name="docker_image"
                value={formApproved.docker_image}
                onChanged={inputApprovedChange}
                type="text"
                readOnly
              />
              <InputField
                label="Username"
                id="username"
                name="username"
                value={formApproved.username}
                onChanged={inputApprovedChange}
                type="text"
                readOnly
              />
              <InputField
                label="Hari"
                id="id_hari"
                name="id_hari"
                value={formApproved.id_hari}
                onChanged={inputApprovedChange}
                type="select"
                option={days}
                placeholder={'Pilih Hari'}
                isLoading={get}
              />
              <InputField
                label="Durasi"
                id="durasi"
                name="durasi"
                value={formApproved.durasi}
                onChanged={inputApprovedChange}
                type="number"
              />
              <InputField
                label="Mesin"
                id="id_mesin"
                name="id_mesin"
                value={formApproved.id_mesin}
                onChanged={inputApprovedChange}
                type="select"
                option={machine}
                placeholder={'Pilih Mesin'}
                isLoading={get}
              />
              <TextEditor
                label="Detail Setujui"
                name="appr_description"
                value={formApproved.appr_description}
                onChanged={inputApprovedChange}
                error={formApproved.error_list.appr_description}
              />
              <div
                style={{
                  marginTop: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <ButtonSubmit
                  color="primary"
                  loading={loadingApproved}
                  onClicked={approvedSubmit}
                >
                  Tambah
                </ButtonSubmit>
              </div>
            </div>
          </Popup>

          <Popup
            trigger={popupRevision}
            setTrigger={setPopupRevision}
            title="Detail Revisi"
          >
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
            >
              <TextEditor
                name="rev_description"
                value={formRejected.rev_description}
                onChanged={inputRejectedChange}
                error={formRejected.error_list.rev_description}
              />
              <div
                style={{
                  marginTop: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <ButtonSubmit
                  color="primary"
                  loading={loadingRejected}
                  onClicked={() => revisionSubmit(formRejected.id_proposal)}
                >
                  Revisi
                </ButtonSubmit>
              </div>
            </div>
          </Popup>
          <ProposalPreviewFormButton>{element}</ProposalPreviewFormButton>
        </Card>
      </PageLayout>
    </>
  );
};

export default ProposalPreview;
